.form-group {
  margin-top: 10px;
  margin-bottom: 10px;
}

.form-group .form-label {
  font-size:     0.92em;
  margin-bottom: 0.25em;
  padding-left:  0.4em;
}
.form-group.required:not(.readonly):not(.error) .form-label {
  padding-left: calc(0px + 0.45em + 5px + 0.4em);
}
.form-group.required:not(.readonly):not(.error) .form-label::before {
  content:  "";
  position: relative;
  display:  inline-block;
  background-color: var(--primaryMain);
  border-radius: 50%;
  width:    0.45em;
  height:   0.45em;

  margin-left: calc(0px - 0.45em - 5px);
  margin-right: 5px;
  margin-bottom: 2px;
}
.form-group.required:not(.readonly) .form-control {
  background-color: var(--inputBgRequired);
}

.form-group.error:not(.readonly) .form-label {
  color: var(--errorText);
}
.form-group.error:not(.readonly) .form-label::before {
  content:  "!";
  position: relative;
  display:  inline-block;
  width:    0.45em;
  height:   0.45em;

  margin-right: 5px;
  margin-bottom: 2px;
}
.form-group.error:not(.readonly) .form-control:not(:focus) {
  background-color: rgba(225, 128, 128, 0.15);
  border-color: var(--errorText);
}

.form-group .tooltip-icon {
  color: var(--disabledText);
}

.form-group .form-text {
  padding-left: 0.4em;
  padding-right: 0.4em;
  margin-top: 0.25em;
  margin-bottom: 0.25em;
  display: block;
  color: var(--secondaryText);
}
.form-group .form-text.pre-text {
  margin-top: -0.25em;
}
.form-group .form-text.post-text {
  text-align: end;
}
.form-group .form-text.help-text {
  color: var(--secondaryText);
}
.form-group .form-text.error-text {
  color: var(--errorText);
}

.form-group .form-control {
  border-radius:  0;
  padding-top:    0;
  padding-bottom: 0;

  height:      2em;
  line-height: 2em;
}
.form-group .input-group-text {
  border-radius: 0;

  height:      2em;
  line-height: 2em;
}
.form-group.no-label .form-control:not(textarea) {
  height:      3em;
  line-height: 3em;
}
.form-group.no-label .input-group-text {
  height:      3em;
  line-height: 3em;
}
@media screen and (min-width: 768px), print {
  .form-group.no-label .form-control:not(textarea) {
    height:      2.5em;
    line-height: 2.5em;
  }
  .form-group.no-label .input-group-text {
    height:      2.5em;
    line-height: 2.5em;
  }
}

.form-group > .tooltip-inline-block  {
  width: 100%;
}

.form-group textarea.form-control {
  line-height:    1.5em;
  padding-top:    0.5em;
  padding-bottom: 0.5em;
}
.form-group textarea.form-control[rows="1"] {
  height: 2em;
}
.form-group textarea.form-control[rows="2"] {
  height: 4em;
}
.form-group textarea.form-control[rows="3"] {
  height: 6em;
}
.form-group textarea.form-control[rows="4"] {
  height: 8em;
}
.form-group textarea.form-control[rows="5"] {
  height: 10em;
}
.form-group textarea.form-control[rows="6"] {
  height: 12em;
}
.form-group textarea.form-control[rows="7"] {
  height: 14em;
}
.form-group textarea.form-control[rows="8"] {
  height: 16em;
}
.form-group textarea.form-control[rows="9"] {
  height: 18em;
}
.form-group textarea.form-control[rows="10"] {
  height: 20em;
}
