.demo-alert {
  max-width: 83rem;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 0 !important;

  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.demo-alert.alert-info .h4.alert-heading {
  font-weight: normal;
  color: var(--primaryText);
  margin-bottom: 0 !important;
}
.demo-alert.alert-info .h4.alert-heading i {
  color: var(--infoText);
}
