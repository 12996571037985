.chip {
  display: inline-block;
  padding: 0.5em 0.75em;
  font-size: 0.875em;
  line-height: 1;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25rem;
  border: 1px solid;
}
.chip:empty {
  display: none
}
.chip .bi {
  margin-right: 0.5em;
}
.btn .chip {
  position:  relative;
  top: -1px
}

.chip.primary {
  color: var(--primaryContrast);
  background-color: var(--primaryMain);
  border-color: var(--primaryMain);
}
.chip.secondary {
  color: var(--secondaryText);
  background-color: rgba(128, 128, 128, 0.12);
  border-color: rgba(128, 128, 128, 0.12);
}
