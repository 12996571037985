/** Stylings adapted from https://github.com/KyleAMathews/react-spinkit */

.circular-progress {
  color: var(--disabledText);
}

.circular-progress > div {
  background-color: currentColor;
}

@-webkit-keyframes progress-bouncedelay {
  0%, 80%, 100% { -webkit-transform: scale(0.0) }
  40% { -webkit-transform: scale(1.0) }
}

@keyframes progress-bouncedelay {
  0%, 80%, 100% {
    -webkit-transform: scale(0.0);
    transform: scale(0.0);
  } 40% {
    -webkit-transform: scale(1.0);
    transform: scale(1.0);
  }
}

/* CIRCLE */

.circular-progress.circle {
  width: 22px;
  height: 22px;
  position: relative;
}

.circular-progress.circle > div {
  background-color: initial;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
}

.circular-progress.circle > div::before {
  content: '';
  display: block;
  margin: 0 auto;
  width: 20%;
  height: 20%;
  background-color: currentColor;
  border-radius: 100%;

  -webkit-animation: progress-bouncedelay 1.2s infinite ease-in-out;
  animation: progress-bouncedelay 1.2s infinite ease-in-out;
  /* Prevent first frame from flickering when animation starts */
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.circular-progress.circle > div:nth-child(2)  { -webkit-transform: rotate(30deg);  transform: rotate(30deg)  }
.circular-progress.circle > div:nth-child(3)  { -webkit-transform: rotate(60deg);  transform: rotate(60deg)  }
.circular-progress.circle > div:nth-child(4)  { -webkit-transform: rotate(90deg);  transform: rotate(90deg)  }
.circular-progress.circle > div:nth-child(5)  { -webkit-transform: rotate(120deg); transform: rotate(120deg) }
.circular-progress.circle > div:nth-child(6)  { -webkit-transform: rotate(150deg); transform: rotate(150deg) }
.circular-progress.circle > div:nth-child(7)  { -webkit-transform: rotate(180deg); transform: rotate(180deg) }
.circular-progress.circle > div:nth-child(8)  { -webkit-transform: rotate(210deg); transform: rotate(210deg) }
.circular-progress.circle > div:nth-child(9)  { -webkit-transform: rotate(240deg); transform: rotate(240deg) }
.circular-progress.circle > div:nth-child(10) { -webkit-transform: rotate(270deg); transform: rotate(270deg) }
.circular-progress.circle > div:nth-child(11) { -webkit-transform: rotate(300deg); transform: rotate(300deg) }
.circular-progress.circle > div:nth-child(12) { -webkit-transform: rotate(330deg); transform: rotate(330deg) }

.circular-progress.circle > div:nth-child(2)::before  { -webkit-animation-delay: -1.1s; animation-delay: -1.1s }
.circular-progress.circle > div:nth-child(3)::before  { -webkit-animation-delay: -1.0s; animation-delay: -1.0s }
.circular-progress.circle > div:nth-child(4)::before  { -webkit-animation-delay: -0.9s; animation-delay: -0.9s }
.circular-progress.circle > div:nth-child(5)::before  { -webkit-animation-delay: -0.8s; animation-delay: -0.8s }
.circular-progress.circle > div:nth-child(6)::before  { -webkit-animation-delay: -0.7s; animation-delay: -0.7s }
.circular-progress.circle > div:nth-child(7)::before  { -webkit-animation-delay: -0.6s; animation-delay: -0.6s }
.circular-progress.circle > div:nth-child(8)::before  { -webkit-animation-delay: -0.5s; animation-delay: -0.5s }
.circular-progress.circle > div:nth-child(9)::before  { -webkit-animation-delay: -0.4s; animation-delay: -0.4s }
.circular-progress.circle > div:nth-child(10)::before { -webkit-animation-delay: -0.3s; animation-delay: -0.3s }
.circular-progress.circle > div:nth-child(11)::before { -webkit-animation-delay: -0.2s; animation-delay: -0.2s }
.circular-progress.circle > div:nth-child(12)::before { -webkit-animation-delay: -0.1s; animation-delay: -0.1s }

/* THREE-BOUNCE */

.circular-progress.three-bounce {
  height: 18px;
}

.circular-progress.three-bounce > div {
  width: 18px;
  height: 18px;
  background-color: currentColor;
  border-radius: 100%;
  display: inline-block;

  -webkit-animation: progress-bouncedelay 1.4s infinite ease-in-out;
  animation: progress-bouncedelay 1.4s infinite ease-in-out;
  /* Prevent first frame from flickering when animation starts */
  -webkit-animation-fill-mode: both;
  animation-fill-mode: both;
}

.circular-progress.three-bounce > div:first-child {
  -webkit-animation-delay: -0.32s;
  animation-delay: -0.32s;
}

.circular-progress.three-bounce > div:nth-child(2) {
  -webkit-animation-delay: -0.16s;
  animation-delay: -0.16s;
}
