.form-check {
  display: flex;
  align-items: center;
  margin-bottom: 0;

  min-height: 3rem;
  font-size: 2rem;
}
@media screen and (min-width: 768px), print {
  .form-check {
    min-height: 2.5rem;
    font-size: 1.5rem;
  }
}
.form-check .form-check-label {
  align-self: stretch;
  padding-left: 0.5em;

  font-size: 1rem;
  display: flex;
  align-items: center;
}

.form-check-input {
  margin-top: 0;
  background-color: var(--midground);
  border-color: var(--inputBorder);
}
.form-check-input:read-only:active {
  filter: unset;
}
.form-check-input:focus {
  border-color: var(--primaryText);
  box-shadow: 0 0 0 1px var(--primaryText);
}
.form-check-input:checked {
  background-color: var(--primaryMain);
  border-color: var(--primaryText);
}
.form-check-input:not([readonly]):hover {
  cursor: pointer;
}
.form-check-input:not([readonly]):not(:checked):hover {
  background-color: var(--primaryShadow);
}
