/* general */

.btn {
  color: var(--secondaryText);
  min-height: 3rem;
  min-width:  3rem;

  display: inline-flex;
  align-items: center;
  justify-content: center;
}
@media screen and (min-width: 768px), print {
  .btn {
    color: var(--secondaryText);
    min-height: 2.5rem;
    min-width:  2.5rem;
  }
}

.btn:hover {
  color: var(--primaryText);
  border-color: var(--primaryText);
}
.btn-check:focus + .btn, .btn:focus {
  box-shadow: 0 0 0 2px var(--primaryText);
}
.btn.disabled, .btn:disabled {
  color: var(--primaryText);
  background-color: var(--actionDisabled);
  border-color: var(--actionDisabled);
}

.btn-close:focus {
  box-shadow: 0 0 0 2px var(--primaryText);
}

/* transparent */

.btn-transparent:hover {
  background-color: var(--hoverBg);
}


/* primary */

.btn-primary {
  color: var(--primaryContrast);
  background-color: var(--primaryMain);
  border-color: var(--primaryMain);
}
.btn-primary:not(.disabled):hover {
  color: var(--primaryContrast);
  background-color: var(--primaryMain);
  border-color: var(--primaryMain);
}
.btn-check:not(.disabled):focus + .btn-primary, .btn-primary:not(.disabled):focus {
  color: var(--primaryContrast);
  background-color: var(--primaryMain);
  border-color: var(--primaryMain);
}
.btn-check:not(.disabled):active + .btn-primary, .btn-check:not(.disabled):checked + .btn-primary, .btn-primary:not(.disabled).active, .btn-primary:not(.disabled):active, .show > .btn-primary.dropdown-toggle {
  color: var(--primaryContrast);
  background-color: var(--primaryMain);
  border-color: var(--primaryMain);
}
.btn-check:active + .btn-primary:focus, .btn-check:checked + .btn-primary:focus, .btn-primary.active:focus, .btn-primary:active:focus, .show > .btn-primary.dropdown-toggle:focus {
  box-shadow: 0 0 0 2px var(--primaryText);
}

.btn-outline-primary {
  color: var(--secondaryText);
  border-color: var(--secondaryText);
}
.btn-outline-primary:not(.disabled):hover {
  color: var(--primaryMain);
  background-color: transparent;
  border-color: var(--primaryMain);
}
.btn-check:not(.disabled):focus + .btn-outline-primary, .btn-outline-primary:not(.disabled):focus {
  color: var(--primaryMain);
  background-color: transparent;
  border-color: var(--primaryMain);
}
.btn-check:not(.disabled):active + .btn-outline-primary, .btn-check:not(.disabled):checked + .btn-outline-primary, .btn-outline-primary:not(.disabled).active, .btn-outline-primary:not(.disabled).dropdown-toggle.show, .btn-outline-primary:not(.disabled):active {
  color: var(--primaryMain);
  background-color: transparent;
  border-color: var(--primaryMain);
}
.btn-check:active + .btn-outline-primary:focus, .btn-check:checked + .btn-outline-primary:focus, .btn-outline-primary.active:focus, .btn-outline-primary.dropdown-toggle.show:focus, .btn-outline-primary:active:focus {
  box-shadow: 0 0 0 2px var(--primaryText);
}
.btn-outline-primary.disabled, .btn-outline-primary:disabled {
  color: var(--disabledText);
  background-color: var(--inputBgDisabled);
  border-color: var(--disabledText);
}


/* secondary */

.btn-secondary {
  color: var(--secondaryContrast);
  background-color: var(--secondaryMain);
  border-color: var(--secondaryMain);
}
.btn-secondary:not(.disabled):hover {
  color: var(--secondaryContrast);
  background-color: var(--secondaryMain);
  border-color: var(--secondaryMain);
}
.btn-check:not(.disabled):focus + .btn-secondary, .btn-secondary:not(.disabled):focus {
  color: var(--secondaryContrast);
  background-color: var(--secondaryMain);
  border-color: var(--secondaryMain);
}
.btn-check:not(.disabled):active + .btn-secondary, .btn-check:not(.disabled):checked + .btn-secondary, .btn-secondary:not(.disabled).active, .btn-secondary:not(.disabled):active, .show > .btn-secondary.dropdown-toggle {
  color: var(--secondaryContrast);
  background-color: var(--secondaryMain);
  border-color: var(--secondaryMain);
}
.btn-check:active + .btn-secondary:focus, .btn-check:checked + .btn-secondary:focus, .btn-secondary.active:focus, .btn-secondary:active:focus, .show > .btn-secondary.dropdown-toggle:focus {
  box-shadow: 0 0 0 2px var(--primaryText);
}

.btn-outline-secondary {
  color: var(--secondaryMain);
  border-color: var(--secondaryMain);
}
.btn-outline-secondary:not(.disabled):hover {
  color: var(--secondaryContrast);
  background-color: var(--secondaryMain);
  border-color: var(--secondaryMain);
}
.btn-check:not(.disabled):focus + .btn-outline-secondary, .btn-outline-secondary:not(.disabled):focus {
  color: var(--secondaryContrast);
  background-color: var(--secondaryMain);
  border-color: var(--secondaryMain);
}
.btn-check:not(.disabled):active + .btn-outline-secondary, .btn-check:not(.disabled):checked + .btn-outline-secondary, .btn-outline-secondary:not(.disabled).active, .btn-outline-secondary:not(.disabled).dropdown-toggle.show, .btn-outline-secondary:not(.disabled):active {
  color: var(--secondaryContrast);
  background-color: var(--secondaryMain);
  border-color: var(--secondaryMain);
}
.btn-check:active + .btn-outline-secondary:focus, .btn-check:checked + .btn-outline-secondary:focus, .btn-outline-secondary.active:focus, .btn-outline-secondary.dropdown-toggle.show:focus, .btn-outline-secondary:active:focus {
  box-shadow: 0 0 0 2px var(--primaryText);
}
.btn-outline-secondary:not(.disabled).disabled, .btn-outline-secondary:not(.disabled):disabled {
  color: var(--disabledText);
  background-color: var(--inputBgDisabled);
  border-color: var(--disabledText);
}


/* danger */

.btn-danger {
  color: var(--primaryContrast);
  background-color: var(--errorText);
  border-color: var(--errorText);
}
.btn-danger:not(.disabled):hover {
  color: var(--primaryContrast);
  background-color: var(--errorText);
  border-color: var(--errorText);
}
.btn-check:not(.disabled):focus + .btn-danger, .btn-danger:not(.disabled):focus {
  color: var(--primaryContrast);
  background-color: var(--errorText);
  border-color: var(--errorText);
  box-shadow: 0 0 0 2px var(--primaryText);
}
.btn-check:not(.disabled):active + .btn-danger, .btn-check:not(.disabled):checked + .btn-danger, .btn-danger:not(.disabled).active, .btn-danger:not(.disabled):active, .show > .btn-danger.dropdown-toggle {
  color: var(--primaryContrast);
  background-color: var(--errorText);
  border-color: var(--errorText);
}
.btn-check:active + .btn-danger:focus, .btn-check:checked + .btn-danger:focus, .btn-danger.active:focus, .btn-danger:active:focus, .show > .btn-danger.dropdown-toggle:focus {
  box-shadow: 0 0 0 2px var(--primaryText);
}

.btn-outline-danger {
  color: var(--secondaryText);
  border-color: var(--secondaryText);
}
.btn-outline-danger:not(.disabled):hover {
  color: var(--errorText);
  background-color: transparent;
  border-color: var(--errorText);
}
.btn-check:not(.disabled):focus + .btn-outline-danger, .btn-outline-danger:not(.disabled):focus {
  color: var(--errorText);
  background-color: transparent;
  border-color: var(--errorText);
}
.btn-check:not(.disabled):active + .btn-outline-danger, .btn-check:not(.disabled):checked + .btn-outline-danger, .btn-outline-danger:not(.disabled).active, .btn-outline-danger:not(.disabled).dropdown-toggle.show, .btn-outline-danger:not(.disabled):active {
  color: var(--errorText);
  background-color: transparent;
  border-color: var(--errorText);
}
.btn-check:active + .btn-outline-danger:focus, .btn-check:checked + .btn-outline-danger:focus, .btn-outline-danger.active:focus, .btn-outline-danger.dropdown-toggle.show:focus, .btn-outline-danger:active:focus {
  box-shadow: 0 0 0 2px var(--primaryText);
}
.btn-outline-danger.disabled, .btn-outline-danger:disabled {
  color: var(--disabledText);
  background-color: var(--inputBgDisabled);
  border-color: var(--disabledText);
}
