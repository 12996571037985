.login-form-wrapper.autologin {
  display: none;
}

#content > .container.login {
  background-color: transparent;
}

.form-signin .form-header {
  justify-content: center;
  background-color: rgba(128, 128, 128, 0.12);
  padding-top: 0.5em;
}

.form-signin h1, .form-signin h2 {
  text-align: center;
}

.form-signin h2 {
  font-size: 1em;
}

.form-signin .form-group {
  margin-top:    1em;
  margin-bottom: 1em;
}
.form-signin button {
  width: 100%;
  height:     3em;
}
