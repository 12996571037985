.footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  background-color: var(--midground);
  height: 2.75rem;
  box-shadow: 0 0 5px 1px var(--elevationShadow);
  border-top: 1px solid var(--elevationShadow);
}
.footer > .container {
  text-align: center;

  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
@media screen {
  .footer > .container {
    padding-right: var(--bs-gutter-x, .75rem);
    padding-left: var(--bs-gutter-x, .75rem);
  }
}
.footer > .container > .footer-container-inner {
  display: flex;
  align-items: center;
}
.footer > .container > .footer-container-inner p {
  margin: 0;
}

.footer > .container a, .footer > .container button {
  color: inherit;
  font-size: 0.9rem;
  text-decoration: none;
  border: none;
}
.footer > .container a:hover,
.footer > .container a:focus,
.footer > .container button:hover,
.footer > .container button:focus {
  text-decoration: underline;
  border: none;
}


.modal-about h2 {
  font-size: 1.2rem;
}
.modal-about .credits-people .card {
  height: 100%;
}
